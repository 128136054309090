import * as React from "react";
import { Typography } from "@mui/material";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";

/** Who 説明文 プロパティ */
export interface IWhoDescriptionProps extends MUIStyledCommonProps<Theme> {
  /** ID */
  id: string;
  /** テキスト */
  strings: string[];
}

const MyRoot = styled("div")({
  display: "flow-root",
  letterSpacing: "0.1rem",
  "& p": {
    float: "left",
  },
});

/** Who 説明文 */
export default function WhoDescription({
  id,
  strings,
  ...props
}: IWhoDescriptionProps) {
  return (
    <MyRoot {...props}>
      {strings.map((text, i) => {
        return (
          <Typography
            variant="body2"
            component="p"
            lineHeight="4.0rem"
            fontFamily={["Yu Gothic"]}
            key={`Who-description-${id}-${i}`}
          >
            {text}
          </Typography>
        );
      })}
    </MyRoot>
  );
}
