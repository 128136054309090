import * as React from "react";
import { styled } from "@mui/system";
import SectionTitle from "../atoms/common/sectionTitle";
import MyName from "../atoms/who/myName";
import WhoDescription from "../atoms/who/whoDescription";
import GoToPageButton from "../atoms/common/goToPageButton";

const MyWhoArea = styled("div")({
    height: "calc(100vh - 10.0rem - 2.0rem)",
    position: "relative"
})
const MyContentsArea = styled("div")({
    display: "flex",
    justifyContent: "center",
    height: "calc(100% - 6.0rem)"
})
const MyContents = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
})
const MyWhoDescription = styled(WhoDescription)({
    width: "40vw"
})
const MyButtonArea = styled("div")({
    height: "6.0rem"
})
const MyWhoImage = styled("div")({
    position: "absolute",
    width: "20.0rem",
    height: "20.0rem",
    backgroundColor: "lightgray",
    bottom: "5.0rem",
    right: "35.0rem",
    zIndex: -1
})

/** Who領域 */
export default function WhoArea() {
    return (
        <MyWhoArea>
            <MyContentsArea>
                <MyContents>
                    <SectionTitle text1="Who [Me]" />
                    <MyName name="永井 結子" yomi="Yuiko Nagai" />
                    <MyWhoDescription id="who-1" strings={[
                        "大学卒業後、クリエイティブカンパニーの株式会社ロフトワークにてクリエイティブディレクターとして従事したのち、独立。",
                        "ユーザーヒアリングの元で行うアイデアや思考のビジュアライズ、情報構造化等、文脈を汲んだビジュアルコミュニケーションを得意とする。",
                        "戦略デザインファームの株式会社BIOTOPEにて、ビジネス戦略領域におけるコミュニケーションデザイナーとしても活動中。"
                    ]} />
                    <MyWhoDescription id="who-2" strings={[
                        "好きなもの ＞ 犬、甘味、春",
                        "最近の関心 ＞ 環境、福祉、公共性"
                    ]} />
                </MyContents>
            </MyContentsArea>
            <MyButtonArea>
                <GoToPageButton to="/" text="TOPに戻る" variant="medium" />
            </MyButtonArea>
            <MyWhoImage />
        </MyWhoArea>
    );
}