import * as React from "react";
import PageHeader from "../components/blocks/pageHeader";
import PageBody from "../components/blocks/pageBody";
import WhoArea from "../components/molecules/whoArea";

/** Whoページ */
export default function Who() {
  return (
    <>
      <PageHeader />
      <PageBody>
        <WhoArea />
      </PageBody>
    </>
  );
}