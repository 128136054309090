import * as React from "react";
import { styled, MUIStyledCommonProps, Theme } from "@mui/system";
import { Typography } from "@mui/material";

/** 名前 プロパティ */
export interface IMyNameProps extends MUIStyledCommonProps<Theme> {
    /** 名前 */
    name: string;
    /** 読み */
    yomi: string;
}

const MyNameArea = styled("div")({
    display: "flex",
    alignItems: "end",
    columnGap: "1.0rem"
})
const MyBorderBottom = styled("div")({
    borderBottom: "15px solid #B3F3E0",
    marginTop: "-2.0rem"
})

/** 名前 */
export default function MyName({ name, yomi, ...props }: IMyNameProps) {
    return (
      <div {...props}>
        <MyNameArea>
            <Typography
                fontSize={"3.5rem"}
                fontWeight={600}
                letterSpacing={"1rem"}
            >
                {name}
            </Typography>
            <Typography
                fontSize={"2.5rem"}
                fontWeight={500}
                letterSpacing={"1rem"}
            >
                {yomi}
            </Typography>
        </MyNameArea>
        <MyBorderBottom />
      </div>
    );
  }